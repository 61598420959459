export default function() {
  /* 表格列头 */
  return [
    // { label: "序号", type: "index", width: 80 },
    { label: "店铺名称", prop: "store_id", width: 180, slots: "store_id" },
    { label: "卡券名称", prop: "couponTitle", width: 180 },
    { label: "卡券描述", prop: "msg", width: 180 },
    { label: "核销状态", prop: "cardStatus", slots: "cardStatus"},
    { label: "领取人手机号", prop: "userPhone", width: 180 },
    { label: "核销人", prop: "receiveUser", width: 180 },
    {
      label: "创建时间",
      prop: "createTime",
      width: 180,
    },
    {
      label: "核销时间",
      prop: "writeOffTime",
      width: 180,
      slots: "writeOffTime",
    },

    {
      label: "操作",
      prop: "operation",
      width: 100,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
