<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装selectForm筛选 -->
    <div class="tableList">
      <!-- 封装selectForm筛选 -->
      <el-form :inline="true">
        <el-form-item label="所属门店:" size="small">
          <el-select
            v-model="listQuery.store_id"
            placeholder="请选择"
            clearable
            multiple
            collapse-tags
          >
            <div v-for="(item, index) in filterStoreList" :key="index">
              <el-option
                :label="item.store_name"
                :value="item.store_id + ''"
              ></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="核销状态:" style="margin:0 20px" size="small">
          <el-select
            v-model="listQuery.cardStatus"
            placeholder="请选择"
            clearable
          >
            <div v-for="(item, index) in cardStatusList" :key="index">
              <el-option :label="item.name" :value="item.id"></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item
          label="领取人手机号: "
          style="margin-right:20px"
          size="small"
        >
          <el-input
            v-model="listQuery.userPhone"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="交易描述: " style="margin-right:20px" size="small">
          <el-input
            v-model="listQuery.message"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-button
          class="addBtn"
          type="primary"
          @click="getListData"
          size="small"
          v-waves
          >搜索</el-button
        >
      </el-form>

      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="store_id" slot-scope="{ row }">
          <StoreToString :store_id="String(row.store_id)" />
        </template>
        <template slot="writeOffTime" slot-scope="{ row }">
          <el-tag
            v-if="row.writeOffTime !== row.createTime"
            type="danger"
            effect="dark"
            size="mini"
          >
            {{ row.writeOffTime }}
          </el-tag>
          <div v-else>
            ——
          </div>
        </template>
        <template slot="cardStatus" slot-scope="{ row }">
          <el-tag v-if="row.cardStatus === 0" effect="dark" size="mini">
            卡券模版
          </el-tag>
          <el-tag
            v-else-if="row.cardStatus === 1"
            effect="dark"
            size="mini"
            type="danger"
          >
            未核销
          </el-tag>
          <el-tag v-else type="success" effect="dark" size="mini">
            已核销
          </el-tag>
        </template>
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>
    </div>
  </div>
</template>

<script>
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import StoreToString from "@/components/filters/storeToString.vue";
import { selectCardCoupon, deleteCardCoupon } from "@/services/mall/coupon";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    StoreToString,
  },
  async created() {
    this.getListData();
  },
  data() {
    return {
      cardStatusList: [
        { id: 1, name: "未核销" },
        { id: 2, name: "已核销" },
      ],
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.store_id =
        listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectCardCoupon(listQuery);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 搜索按钮 */
    async search(listQuery) {
      this.listQuery = listQuery;
      this.getListData();
    },
    /* 分页 */
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 删除 */
    handleDelete(row) {
      this.$confirm(`确定删除卡券"${row.couponTitle}"吗?`, "禁用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        deleteCardCoupon({ id: row.id }).then((res) => {
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
