import axios from "axios";
import { request } from "@/utils/request";

/**
 * 查询
 * @param {*} data
 * @returns
 */
export function selectCardCoupon(data) {
  return request({
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
    url: `/admin/cardCoupon/selectCardCoupon`,
    method: "POST",
    data,
  });
}

/**
 * 新增
 * @param {*} data
 * @returns
 */
export function addVipcardRecoder(data) {
  return request({
    url: `/admin/cardCoupon/add`,
    method: "POST",
    data,
  });
}

/*删除 */
export function deleteCardCoupon(data) {
  return request({
    url: `/admin/cardCoupon/delete`,
    method: "POST",
    data,
  });
}
